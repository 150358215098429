import { useEffect, useState, useContext } from "react";
import axios from '../../config/axios';
import moment from '../../config/moment';

//importing components
import Layout from "../../Components/Layout/Layout"
import { Button, Col, DatePicker, Form, Input, Pagination, Row, Select } from "antd";
import { SiMicrosoftexcel } from 'react-icons/si';
import Loader from "../../Components/Library/Loader/Loader";
import TransactionsTable from "../../Components/TransactionsTable/TransactionsTable";
import { objToQueryString } from "../../utils/misc";

//importing context
import { AuthContext } from "../../Contexts/AuthContext";
import DepositsTable from "../../Components/TransactionsTable/DepositsTable";
import WithdrawsTable from "../../Components/TransactionsTable/WithdrawsTable";

const Withdraws = () => {

    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;
    const { user } = useContext(AuthContext);

    const [limit, setLimit] = useState(20);
    const [loading, setLoading] = useState(true);
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [dataCount, setDataCount] = useState(0);
    const [filters, setFilters] = useState({});
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        axios.get('/admin/users', {
            withCredentials: true, params: {
                page: 1,
                limit: 20,
                role: 'admin'
            }
        }).then(response => {
            setAdmins(response.data.results);

        })
    }, []);

    useEffect(() => {
        setLoading(true);
        window.scrollTo(0, 0);
        axios.get('/transactions/admin/withdraws', {
            withCredentials: true, params: {
                page: page,
                limit: limit,
                get_all: true,
                gateway_id: filters.gateway_id ? filters.gateway_id : null,
                status: filters.status ? filters.status : null,
                start: filters.range ? moment(filters.range[0]).format('YYYY-MM-DD') : null,
                end: filters.range ? moment(filters.range[1]).format('YYYY-MM-DD') : null,
            }
        }).then(response => {
            setTransactions(response.data.results);
            setDataCount(response.data.count);
            setLoading(false);
        })
    }, [page, limit, filters]);

    //defining applyFilter function
    const applyFilter = (values) => {
        setFilters(values);
    }



    return (
        <Layout>
            <h1 className="title">Withdraw Management</h1>
            <div className="card">
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <span className="card__title">All Withdrawals</span>
                    {
                        user.is_super_admin &&
                        <a href={`/excel/transaction?${objToQueryString({
                            page: page,
                            limit: 4000,
                            loan: filters.loan ? filters.loan : null,
                            gateway_id: filters.gateway_id ? filters.gateway_id : null,
                            status: filters.status ? filters.status : null,
                            type: filters.type ? filters.type : null,
                            created_by: filters.created_by ? filters.created_by : null,
                            start: filters.range ? moment(filters.range[0]).format('YYYY-MM-DD') : null,
                            end: filters.range ? moment(filters.range[1]).format('YYYY-MM-DD') : null,
                        })}`} target={'_blank'} rel='noreferrer' style={{ display: 'flex', alignItems: 'center' }}>
                            <SiMicrosoftexcel size={20} style={{ marginRight: '.5rem' }} />
                            <span style={{ color: 'var(--color-primary)', cursor: 'pointer' }} className="card__title">Export To Excel</span>
                        </a>
                    }
                </div>

                <div className="filter">
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={applyFilter}
                    >
                        <Row gutter={[16, 16]}>

                            <Col sm={4}>
                                <Form.Item label="Loan ID" name={'loan'}>
                                    <Input placeholder="Search by loan ID" />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Gate. ID" name={'gateway_id'}>
                                    <Input placeholder="Filter by gateway ID" />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Date Range" name={'range'}>
                                    <RangePicker />
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Deposit Status" name={'status'}>
                                    <Select defaultValue={null}>
                                        <Select.Option value={null}>Show All</Select.Option>
                                        <Select.Option value={'pending'}>Pending</Select.Option>
                                        <Select.Option value={'success'}>Success</Select.Option>
                                        <Select.Option value={'failed'}>Failed</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col sm={4}>
                                <Form.Item label="Type" name={'type'}>
                                    <Select defaultValue={null}>
                                        <Select.Option value={null}>Show All</Select.Option>
                                        <Select.Option value={'get'}>Get</Select.Option>
                                        <Select.Option value={'pay'}>Pay</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col sm={4}>
                                <Form.Item label="Created By" name={'created_by'}>
                                    <Select defaultValue={null}>
                                        <Select.Option value={null}>N/A</Select.Option>
                                        {
                                            admins.map((admin, index) => {
                                                return <Select.Option key={index} value={admin.id}>{admin.first_name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col sm={4} style={{ marginLeft: 'auto', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Form.Item label="Actions">
                                    <Button htmlType="submit" type="primary" style={{ flex: '0 0 48%', marginRight: '.5rem' }}>Apply</Button>
                                    <Button onClick={() => {
                                        form.resetFields();
                                        setFilters({});
                                    }
                                    } type="danger" style={{ flex: '0 0 48%' }}>Clear</Button>
                                </Form.Item>
                            </Col>
                        </Row>


                    </Form>
                    <Row>
                        <Col sm={4}></Col>
                    </Row>
                </div>

                {
                    loading ?
                        <Loader height={'75vh'} />
                        :
                        <>
                            <WithdrawsTable transactions={transactions} />
                            <div className="pagination">
                                <span>Showing {((page - 1) * limit) + 1} to {((page - 1) * limit) + limit > dataCount ? dataCount : ((page - 1) * limit) + limit} of {dataCount} entries</span>
                                <Pagination
                                    pageSize={limit}
                                    total={dataCount}
                                    current={page}
                                    onChange={value => setPage(value)}
                                    onShowSizeChange={(curr, size) => setLimit(size)}
                                />
                            </div>
                        </>
                }

            </div>
        </Layout>
    );
}

export default Withdraws;