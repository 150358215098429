import styles from './Layout.module.scss';
import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';

//importing components
import { ImStatsBars } from 'react-icons/im';
import { message } from 'antd';
import { GiTakeMyMoney, GiReceiveMoney, GiSandsOfTime, GiPayMoney } from 'react-icons/gi';
import { HiOutlineUsers } from 'react-icons/hi';
import { BiLogOutCircle } from 'react-icons/bi';
import { MdOutlineSettingsSuggest } from 'react-icons/md';
import { AiOutlineMessage } from 'react-icons/ai';
import { GoReport } from 'react-icons/go';
import { RiHistoryLine } from 'react-icons/ri';


//importing context
import { AuthContext } from '../../Contexts/AuthContext';



const Layout = (props) => {

    const location = useLocation();
    const { user, setUser, setIsAuthenticated } = useContext(AuthContext);

    //defining signOutHandler function
    const signOutHandler = () => {
        setIsAuthenticated(false);
        setUser(null);
        localStorage.removeItem('device_id');
        message.success('Signed Out Successfully!')
        props.history.push('/login');
    }

    return (
        <div className={styles.main}>
            <header className={styles.header}>
                <div>
                    <img className={styles.logo} src="https://azima.app/wp-content/uploads/2021/09/Azima-Logo.png" alt="" />
                </div>
                <div className={styles.user}>
                    <img className={styles.avatar} src={user.user_data.dp ? user.user_data.dp : "/avatar.png"} alt="" />
                    <div className={styles.user__text}>
                        <span style={{ fontSize: '1.2rem' }}>Welcome,</span>
                        <span className={styles.name}>{user.user_data.first_name} {user.user_data.last_name}</span>
                    </div>
                </div>
            </header>
            <nav className={styles.sidebar}>
                <ul>
                    <Link to={'/'}>
                        <li className={location.pathname === '/' ? styles.selected : null}>
                            <ImStatsBars size={20} />
                            <span>Home</span>
                        </li>
                    </Link>
                    <Link to={'/users'}>
                        <li className={location.pathname.includes('/users') ? styles.selected : null}>
                            <HiOutlineUsers size={20} />
                            <span>Users</span>
                        </li>
                    </Link>
                    <Link to={'/deposits'}>
                        <li className={location.pathname === '/deposits' ? styles.selected : null}>
                            <GiTakeMyMoney size={20} />
                            <span>Deposits</span>
                        </li>
                    </Link>
                    <Link to={'/withdraws'}>
                        <li className={location.pathname === '/withdraws' ? styles.selected : null}>
                            <GiReceiveMoney size={20} />
                            <span>Withdraws</span>
                        </li>
                    </Link>
                    <Link to={'/interests'}>
                        <li className={location.pathname === '/interests' ? styles.selected : null}>
                            <GiPayMoney size={20} />
                            <span>Interests</span>
                        </li>
                    </Link>

                    {/* {
                        user.user_data.is_super_admin &&
                        <>
                            <Link to={'/comm-reports'}>
                                <li className={location.pathname === '/comm-reports' ? styles.selected : null}>
                                    <AiOutlineMessage size={20} />
                                    <span>Comm Reports</span>
                                </li>
                            </Link>
                            <Link to={'/config'}>
                                <li className={location.pathname === '/config' ? styles.selected : null}>
                                    <MdOutlineSettingsSuggest size={20} />
                                    <span>Config</span>
                                </li>
                            </Link>
                            <Link to={'/reports'}>
                                <li className={location.pathname === '/reports' ? styles.selected : null}>
                                    <GoReport size={20} />
                                    <span>Reports</span>
                                </li>
                            </Link>
                            <Link to={'/logs'}>
                                <li className={location.pathname === '/logs' ? styles.selected : null}>
                                    <RiHistoryLine size={20} />
                                    <span>Logs</span>
                                </li>
                            </Link>
                            <Link to={'/due-loans'}>
                                <li className={location.pathname === '/due-loans' ? styles.selected : null}>
                                    <GiSandsOfTime size={20} />
                                    <span>Due Loans Report</span>
                                </li>
                            </Link>
                        </>
                    } */}


                    <li
                        style={{ marginTop: 'auto', marginBottom: '2rem', color: 'var(--color-red)' }}
                        onClick={signOutHandler}
                    >
                        <BiLogOutCircle size={20} color='var(--color-red)' />
                        <span>Sign Out</span>
                    </li>

                </ul>
            </nav>
            <div className={styles.main__body}>
                {props.children}
            </div>
        </div>
    );
};

export default Layout;