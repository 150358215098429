import "./App.css";
import "./index.css";
import "./styles/global.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//importing components
import Login from "./Views/Login/Login";
import Landing from "./Views/Landing/Landing";
import PrivateRoute from "./HOComponents/PrivateRoute";
import Loans from "./Views/Loans/Loans";
import Users from "./Views/Users/Users";
import UserDetails from "./Views/UserDetails/UserDetails";
import Config from "./Views/ Config/Config";
import UserExcel from "./Views/Excels/UserExcel";
import LoanExcel from "./Views/Excels/LoanExcel";
import TransactionExcel from "./Views/Excels/TransactionExcel";
import CommReports from "./Views/CommReports/CommReports";
import SuperAdminRoute from "./HOComponents/SuperAdminRoute";
import Reports from "./Views/Reports/Reports";
import Logs from "./Views/Logs/Logs";
import DueLoans from "./Views/DueLoans/DueLoans";
import Deposits from "./Views/Deposits/Deposits";
import Withdraws from "./Views/Withdraws/Withdraws";
import Interests from "./Views/Interest/Interests";

function App() {

    return (

        <Router>
            <Route path="/login" component={Login} exact />
            <Switch>
                <PrivateRoute roles={["admin"]} path="/" component={Landing} exact />
                {/* <PrivateRoute roles={["admin"]} path="/loans" component={Loans} exact /> */}
                <PrivateRoute roles={["admin"]} path="/users" component={Users} exact />
                <PrivateRoute roles={["admin"]} path="/users/:userID" component={UserDetails} exact />
                <PrivateRoute roles={["admin"]} path="/deposits" component={Deposits} exact />
                <PrivateRoute roles={["admin"]} path="/withdraws" component={Withdraws} exact />
                <PrivateRoute roles={["admin"]} path="/interests" component={Interests} exact />
                {/* <SuperAdminRoute roles={["admin"]} path="/config" component={Config} exact />
                <PrivateRoute roles={["admin"]} path="/excel/user" component={UserExcel} exact />
                <PrivateRoute roles={["admin"]} path="/excel/due-loans/:start/:end" component={UserExcel} exact />
                <SuperAdminRoute roles={["admin"]} path="/due-loans" component={DueLoans} exact />
                <PrivateRoute roles={["admin"]} path="/excel/loan" component={LoanExcel} exact />
                <PrivateRoute roles={["admin"]} path="/excel/transaction" component={TransactionExcel} exact />
                <SuperAdminRoute roles={["admin"]} path="/comm-reports" component={CommReports} exact />
                <SuperAdminRoute roles={["admin"]} path="/reports" component={Reports} exact />
                <SuperAdminRoute roles={["admin"]} path="/logs" component={Logs} exact /> */}
            </Switch>
            {/* handling 404 error page */}
            {/* <Route path="*" component={EmptyPage} exact /> */}
        </Router>

    );
}

export default App;
