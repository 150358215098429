import React, { useEffect, useState } from 'react';
import moment from '../../config/moment';
import axios from '../../config/axios';
import styles from './Landing.module.scss';

//importing context
import { AuthContext } from '../../Contexts/AuthContext';

//importing components
import Layout from '../../Components/Layout/Layout';
import {
    Row, Col, DatePicker, Button
} from 'antd';
import { BsInfoCircle } from 'react-icons/bs';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

const Landing = () => {

    const { user } = useContext(AuthContext);



    const { RangePicker } = DatePicker;
    const [start, setStart] = useState(`${moment(new Date()).format('YYYY-MM')}-01`);
    const [end, setEnd] = useState(`${moment(new Date()).format('YYYY-MM-DD')}`);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get(`/fundamentals/reports`, {
            withCredentials: true, params: {
                start: `${moment(new Date()).format('YYYY-MM')}-01`,
                end: `${moment(new Date()).format('YYYY-MM-DD')}`
            }
        }).then(response => {
            setLoading(false)
            setData(response.data);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        axios.get(`/fundamentals/reports`, {
            withCredentials: true, params: {
                start: start,
                end: end
            }
        }).then(response => {
            setLoading(false)
            setData(response.data);
        });
    }, [end, start]);

    return (
        <Layout>

            <div className='card'>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ marginTop: '1rem' }}>Reports:</span>
                    <br />
                    <RangePicker
                        onChange={values => {
                            // console.log('change')
                            setStart(moment(values[0]).format('YYYY-MM-DD'));
                            setEnd(moment(values[1]).format('YYYY-MM-DD'))
                        }}
                        defaultValue={[moment(start, 'YYYY-MM-DD'), moment(end, 'YYYY-MM-DD')]}
                        style={{ width: 'fit-content' }}

                    />
                </div>
                <br />
                <Row
                    gutter={[16, 16]}
                >

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>User Count</span>
                            </div>
                            <span className={styles.data}>{loading ? '...' : data.user_count}</span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Total Depositors</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.total_depositors}
                            </span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Total Deposits (Count)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.deposit_count}
                            </span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Total Deposits (Amount)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.deposit_amount} UGX
                            </span>
                        </div>
                    </Col>



                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>First Time Depositors (Count)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.first_time_depositors_count}
                            </span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>First Time Depositors (Amount)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.first_time_depositors_amount} UGX
                            </span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Repeat Depositors (Count)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.repeat_depositors_count}
                            </span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Repeat Depositors (Amount)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.repeat_depositors_amount} UGX
                            </span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Total Reward Withdrawals (Count)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.withdraw_count}
                            </span>
                        </div>
                    </Col>

                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Total Reward Withdrawals (Amount)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.withdraw_amount} UGX
                            </span>
                        </div>
                    </Col>


                    <Col span={8}>
                        <div className={styles.card}>
                            <div className={styles.card__header}>
                                <BsInfoCircle size={20} color={'var(--color-primary)'} />
                                <span>Total Interest Amount (Amount)</span>
                            </div>
                            <span className={styles.data}>
                                {loading ? '...' : data.total_interest_amount} UGX
                            </span>
                        </div>
                    </Col>


                </Row>
                <br />
                <b></b>


            </div>

            {/* <div className='card' style={{ minHeight: '80vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img width={'200px'} src="/welcome.svg" alt="" />
                <br />
                <span>Welcome {user.first_name}!</span>
                <br />
                <div>
                    <Link to={'/users'}><Button>Manage Users</Button></Link>
                    <Link style={{ margin: '0 1rem' }} to={'/withdraws'}><Button>Manage Withdraws</Button></Link>
                    <Link to={'/deposits'}><Button>Manage Deposits</Button></Link>

                </div>
            </div> */}


        </Layout>
    );
};

export default Landing;