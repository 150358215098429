import { Button } from "antd";
import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import Loader from "../../Components/Library/Loader/Loader";
import axios from '../../config/axios';
import moment from '../../config/moment';
import { useQuery } from "../../utils/hooks";

//importing context
import { AuthContext } from "../../Contexts/AuthContext";
import { useContext } from "react";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;




const LoanExcel = (props) => {

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(true);
    const [dataset, setDataset] = useState([]);

    let query = useQuery();


    useEffect(() => {
        if (!user.is_super_admin) {
            window.close();
            return;
        }
        // console.log(query.get('start'));
        setLoading(true);
        axios.get('/admin/loans' + window.location.search, {
            withCredentials: true,
        }).then(response => {
            let processedData = [];
            response.data.results.forEach(loan => {
                loan.phone_number = loan.user.phone_number
                processedData.push(loan)
            })
            setDataset(processedData);
            setLoading(false);
        });
    }, []);



    return (
        <>
            {
                loading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
                        <Loader />
                        <br />
                        <span>Generating Excel File...</span>
                    </div>
                    :
                    <ExcelFile
                        element={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                                <Button type="primary">Download Excel</Button>
                            </div>
                        }
                        filename={"azima-loans-" + moment(new Date()).format('MMMM-DD-YY-LT')}
                    >
                        <ExcelSheet data={dataset} name={'Loans'}>
                            <ExcelColumn label="ID" value="id" />
                            <ExcelColumn label="Customer Phone Number" value="phone_number" />
                            <ExcelColumn label="Loan Name" value="loan_name" />
                            <ExcelColumn label="Amount Requested" value="amount_requested" />
                            <ExcelColumn label="Interest (%)" value="interest" />
                            <ExcelColumn label="Transaction Fee (UGX)" value="transaction_fee" />
                            <ExcelColumn label="Processing Fee (UGX)" value="processing_fee" />
                            <ExcelColumn label="Amount To Be Paid (UGX)" value="total_to_be_paid" />
                            <ExcelColumn label="Amount Paid (UGX)" value="amount_paid" />
                            <ExcelColumn label="Request Time" value="created_at" />
                            <ExcelColumn label="Sanction Time" value="sanction_time" />
                            <ExcelColumn label="Due Date" value="date_for_payment" />
                            <ExcelColumn label="Status" value="status" />
                            <ExcelColumn label="Returned Completely" value="returned_completely" />
                            <ExcelColumn label="Beyonic ID" value="beyonic_trx_id" />
                            <ExcelColumn label="Loan Tracker" value="tracker" />
                        </ExcelSheet>
                    </ExcelFile>
            }
        </>
    )
}

export default LoanExcel;