import { Button } from "antd";
import React, { useEffect, useState } from "react";
import ReactExport from "react-export-excel-xlsx-fix";
import Loader from "../../Components/Library/Loader/Loader";
import axios from '../../config/axios';
import moment from '../../config/moment';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;




const TransactionExcel = (props) => {
    const [loading, setLoading] = useState(true);
    const [dataset, setDataset] = useState([]);

    useEffect(() => {
        console.log(window.location.search)
        setLoading(true);
        axios.get('/admin/transactions' + window.location.search, { withCredentials: true }).then(response => {
            let transactions = response.data.results;
            transactions.forEach(trx => {
                trx.loan_id = trx.loan?.id;
                trx.loan_name = trx.loan?.loan_name;
            })
            console.log(transactions)
            setDataset(transactions);
            setLoading(false);
        });
    }, []);



    return (
        <>
            {
                loading ?
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
                        <Loader />
                        <br />
                        <span>Generating Excel File...</span>
                    </div>
                    :
                    <ExcelFile
                        element={
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                                <Button type="primary">Download Excel</Button>
                            </div>
                        }
                        filename={"azima-transactions-" + moment(new Date()).format('MMMM-DD-YY-LT')}
                    >
                        <ExcelSheet data={dataset} name={'Transactions'}>
                            <ExcelColumn label="ID" value="id" />
                            <ExcelColumn label="Loan ID" value="loan_id" />
                            <ExcelColumn label="Loan Name" value="loan_name" />
                            <ExcelColumn label="Amount" value="amount" />
                            <ExcelColumn label="Type" value="type" />
                            <ExcelColumn label="Timestamp" value="created_at" />
                            <ExcelColumn label="Beyonic ID" value="beyonic_trx_id" />
                            <ExcelColumn label="Status" value="status" />
                        </ExcelSheet>
                    </ExcelFile>
            }
        </>
    )
}

export default TransactionExcel;