import { useContext, useState } from 'react';
import styles from './Login.module.scss';
import axios from '../../config/axios';

//importing components
import { Button, Input, message } from 'antd';
import { FaSignInAlt } from 'react-icons/fa';
import OTPInput from "otp-input-react";

//importing context
import { AuthContext } from '../../Contexts/AuthContext';

const Login = (props) => {

    const authContext = useContext(AuthContext);

    const [screen, setScreen] = useState(1);
    const [loading, setLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [pin, setPin] = useState('');
    const [otp, setOtp] = useState('');
    const [hashedOtp, setHashedOtp] = useState(null);

    //defining sendOtp function
    const sendOtp = e => {

        e.preventDefault();

        if (!phoneNumber || !pin) {
            message.error('Phone Number and Pin are required');
            return;
        }

        setLoading(true);

        let payload = {
            phone_number: phoneNumber
        }

        axios.post('/otp/send', payload).then(response => {
            setHashedOtp(response.data.hashed_otp);
            setScreen(2);
            setLoading(false);
        });
    }

    //defining login handler function
    const loginHandler = e => {

        e.preventDefault();

        setLoading(true);

        let payload = {
            user_given_otp: otp,
            hashed_otp: hashedOtp,
            phone_number: phoneNumber,
            password: pin
        }

        axios.post('/admin/login', payload).then(response => {
            console.log(response.data.user.user_data.role);
            if (response.data.user.user_data.role === "admin") {
                authContext.setUser(response.data.user);
                authContext.setIsAuthenticated(true);
                localStorage.setItem('device_id', response.data.access_token);
                props.history.push("/");
            } else {
                message.error("Unauthorized!");
                setLoading(false);
            }
        }).catch(error => {
            message.error("Invalid credentials!");
            setLoading(false);
        });
    }

    return (
        <div className={styles.container}>
            <form action="" className={styles.form}>

                <div className={styles.logo}>
                    <img src="/icon.png" alt="" />
                </div>

                <span className={styles.title}>Azima Club Dashboard</span>
                <span className={styles.subtitle}>Welcome Back! Sign in to continue.</span>

                {
                    screen === 1 ?
                        <>
                            <label htmlFor="">Phone Number</label>
                            <Input
                                placeholder='Enter your phone number'
                                type={'number'}
                                maxLength={13}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                            <br />
                            <label htmlFor="">Pin</label>
                            <Input.Password
                                placeholder='Enter your pin'
                                type={'number'}
                                maxLength={6}
                                onChange={(e) => setPin(e.target.value)}
                                onPressEnter={sendOtp}
                            />
                            <br />

                        </>
                        :
                        <div className={styles.otp_wrapper}>
                            <label htmlFor="">Please enter the OTP sent to {phoneNumber}</label>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                secure
                                inputClassName={styles.otp}
                            />
                        </div>
                }
                {
                    screen === 1 ?
                        <Button
                            onClick={sendOtp}
                            disabled={loading}
                            loading={loading}
                            type={'primary'}
                        >
                            <span>Get OTP</span>
                            <FaSignInAlt size={20} />
                        </Button> :
                        <Button
                            onClick={loginHandler}
                            disabled={loading}
                            loading={loading}
                        >
                            <span>Sign In</span>
                            <FaSignInAlt size={20} />
                        </Button>
                }
            </form>
        </div>
    );
};

export default Login;