import axios from '../config/axios';

export const loginService = (data) => {
    return axios.post('/api/users/login', data, { withCredentials: false }).then(response => {


        localStorage.setItem('device_id', response.data.access_token);

        return {
            success: true,
            response: response
        }
    }).catch(error => {
        let message = '';

        if (error.response.data.detail === 'User not found') {
            message = 'No account associated with this phone number is found!';
        } else {
            message = `Invalid credentials! Use Reset Password option if you've forgot your password`;
        }

        return {
            success: false,
            message: message
        }
    });
}


export const registrationService = (data) => {
    return axios.post('/api/users/register', data).then(response => {

        if (response.status === 201) {

            return {
                success: true,
                message: 'Your merchant account was created successfully! Please login'
            }

        }

    }).catch(error => {

        if (error.response.data.phone_number[0] === 'user with this phone number already exists.') {

            return {
                success: false,
                message: 'A user with this phone number already exists!'
            }
        }
    });
}




export const authenticateService = () => {
    return axios.get(`/users/authenticate`, { withCredentials: true }).then(response => {
        if (response.status === 200) {
            return {
                success: true,
                response: response
            }
        }
    }).catch((error) => {
        if (error) {
            return {
                success: false,
                response: error.response
            }
        }
    });
}


