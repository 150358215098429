import React, { createContext, useState, useEffect } from 'react';

//importing api services
import { authenticateService } from "../api/auth";

//importing components
import Loader from '../Components/Library/Loader/Loader';

export const AuthContext = createContext();

export default ({ children }) => {

    const [user, setUser] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);


    useEffect(() => {

        authenticateService().then(res => {
            if (res.success) {
                const { response } = res;
                //checking user role
                if (response.data.user.user_data.role === 'admin') {
                    console.log('user is admin')
                    setIsAuthenticated(true);
                    setUser(response.data.user);
                    setIsLoaded(true);
                } else {
                    setIsLoaded(true);
                    setUser(null);
                }


            } else {
                setIsLoaded(true);
                setUser(null);
            }
        })

        // if (response.status === 200) {
        //     if (response.data.user.role.includes('UN7vBlHb')) {
        //         setIsAuthenticated(true);
        //         setUser(response.data.user);
        //         setIsLoaded(true);
        //     } else {
        //         window.location.replace('https://leanexa.com.bd');
        //     }
        // }

        // setIsLoaded(true);
    }, []);


    return (
        <div>
            {!isLoaded ? <Loader height={'100vh'} /> : <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated }}>

                {children}

            </AuthContext.Provider>}
        </div>
    )
}
