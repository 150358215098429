export const isPartiallyPermitted = (user) => {
    return user.phone_number == '256775317366';
}

export const objToQueryString = function (obj) {
    var str = [];
    for (var p in obj) {

        if (obj[p] !== null) {
            if (obj.hasOwnProperty(p)) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    }
    return str.join("&");
}

export const getUserData = (info) => {
    if (info) {
        return JSON.parse(JSON.parse(info))
    } else {
        return {}
    }
}

export const calculatePercentage = (amount, percentage) => {
    let percentageAmount = (amount * percentage) / 100;
    return Math.ceil(percentageAmount);
}

export const formattedAmount = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};